img {
   width: 100%;
   max-width: 1080px;
   display: block;
   margin: 0 auto;
}

.article-header {
    img {
        max-width: 100%;
    }
}

.small-img {
    max-width: 400px;
}

footer {
    margin: 0 auto;
    max-width: 1080px;
    text-align: center;
    border-bottom: 6em;
    bottom: 6em;
    img {
        width: auto;
        display: inline-block;
        max-width: 100%;
    }
}
