$sans-serif: 'Roboto', 'Helvetica', 'Arial', sans-serif;
$serif: 'Garamond', 'Times New Roman', serif;
$monospaced : 'Inconsolata', monospace;


body {
    @include font-feature-settings("kern", "liga", "pnum");
    color: $black;
    font-family: $sans-serif;
    font-size: 16px;
    font-size: 1em;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    line-height: 1.5;
}

h1, h2, h3, h4, h5, h6 {
    line-height: 1.2;
    margin: 0;
}

h1 {
    font-size: 40px;
    @include media ($mobile) {
        font-size: 32px;
    }
}

h2 {
    font-size: 42px;
    font-weight: 300;
    @include media ($mobile) {
        font-size: 28px;
    }
}

h3 {
    font-size: 40px;
    @include media ($mobile) {
        font-size: 32px;
    }
}


p {
    font: {
        weight: 300;
        size: 20px;
    }

    line-height: 1.6;
    
}

blockquote {
    p {
        //text-indent: 0;
    }
}

sup {
    font-family: $sans-serif;
    font-size: 16px;
    padding: 0 1px;
    border-bottom: 3px solid #000000;
    font-style: normal; 
}

a {
    color: $black;
    text-decoration: none;
    transition: color 0.1s linear;

    &:active,
    &:focus,
    &:hover {
    //color: darken($action-color, 15%);
    }

    &:active,
    &:focus {
        outline: none;
    }
}

nav {

}

