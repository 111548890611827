/// ////////////////////////////////////////////////////////////////////////////
///
/// C O L O R S
/// 
/// In this file you find all color definitions. 
/// 
/// 
/// --------------------------------------
/// 
/// T o C
/// 
/// --------------------------------------
/// 
/// 1. Variables
///    1.1. Basic
///    1.2. Colors
///    1.3. Dark Colors
///    1.4. Light Colors
/// 2. Mixins
/// 3. Color Classes
///    3.1. Font Colors
///    3.2. Background Colors
///    3.3. Border Colors
/// 
/// ////////////////////////////////////////////////////////////////////////////



////// 1. Variables



$black: #000000;
$white: #ffffff;

$colors: (
    black: #000000,
    white: #ffffff,
    merino: #f9f7ed,
    snow-drift: #f0f4ef,
    granny-apple: #e4f9e6,
    link-water: #cee7f2,
    zircon: #fafbff,
    whisper: #f6f4f9,
    soft-peach: #f7f2f5,
    fantasy: #f9efed
);



@mixin color($color) {
    color: map-get($colors, $color);
}

@mixin background-color($color) {
    background-color: map-get($colors, $color);
}

@mixin border-color($color) {
    border-color: map-get($colors, $color);
}

@each $color, $value in $colors {
    .bg-#{$color} {
        background-color: $value;
    }
}




body {
    @include background-color(whisper);
}


.header-bar {
    @include background-color(white);
}

.article {
    article {
        background-color: $white;
    }
}





