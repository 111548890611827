nav {

}

.header-navigation {
    padding: 0;
    @include media ($tablet-up) {
        display:table-cell;
        vertical-align:middle;
        text-align: right;

        a {
            padding: 2em;
        }
    }

    

    @include media ($mobile) {
        display: none;

        .navigation-is-open & {
            display: inline;
        }

        a {
            display: block;
            padding: 1em 0;
        }
    }
}

.nav-trigger {
    float: right;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 20px;
    display: none;

    @include media ($mobile) {
        display: inline;
    }

    .navigation-is-open & {
        text-decoration: line-through;
    }
}

