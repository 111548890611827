.data-table {
    div {
        padding: 16px 0 0;
    }
    .data-head {
        border-top: 1px solid;
        @include border-color(black);
    }

    .marked {
        p {
        font-weight: 700;
        font-size: 32px;
        }
    }

    p {
        padding: 0;
        margin: 0;
    }

    h2 {
        font-size: 28px;
    }
}
