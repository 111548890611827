$mobile: new-breakpoint(max-width 640px 4);
$tablet: new-breakpoint(min-width 641px max-width 768px 12);
$tablet-up: new-breakpoint(min-width 768px 12);
$desktop: new-breakpoint(min-width 768px 12);

/// ////////////////////////////////////////////////////////////////////////////
///
/// L A Y O U T 
/// 
/// In this file you find general setups. If you're looking for specific
/// components (i.e. navigations, forms, ...) you'll find them in the components
/// directory. 
/// 
/// 
/// --------------------------------------
/// 
/// T o C
/// 
/// --------------------------------------
/// 
/// 1. General 
/// 2. Columns
/// 3. Article
/// 
/// ////////////////////////////////////////////////////////////////////////////


/// / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / /
/// 
/// 1. G e n e r a l
/// 
/// / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / /

body {
    margin: 0;
    height: 100%;
}

.wrapper {
    
}

.center {
    text-align: center;
}

.header-bar {
    display: table;
    min-height: 60px;
    padding: 1em 2em;
    width: 100%;
}

/// / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / /
/// 
/// 2. C o l u m n s
/// 
/// / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / /


.full {
    @include media ($mobile) {
        @include span-columns(4);
    }

    @include media ($tablet-up) {
        @include span-columns(12);
    }
}


.one-half {
    @include media ($mobile) {
        @include span-columns(4);
    }

    @include media ($tablet-up) {
        @include span-columns(6);
    }
}

.one-third {
    @include media ($mobile) {
        @include span-columns(4);
    }

    @include media ($tablet-up) {
        @include span-columns(4);
    }
}

.one-fourth {
    @include media ($mobile) {
        @include span-columns(2);
    }

    @include media ($tablet-up) {
        @include span-columns(3);
    }
}

.two-third {
    @include media ($mobile) {
        @include span-columns(4);
    }

    @include media ($tablet-up) {
        @include span-columns(8);
    }
}

.one-half, .one-third, .one-fourth, .two-third {
    @include pad(20px 40px);
}

.no-pad {
    padding: 0;
}

.with-pad {
    @include pad(20px 40px);
}

/// / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / /
/// 
/// 3. A r t i c l e
/// 
/// / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / /


.article {
    height: 100%;

    .article-header {
        max-height: 100%;
        overflow: hidden;
    }

    section {
        @include outer-container;
    }

    article {
        padding: 40px;
        @include media ($tablet-up) {
            position: relative;
            top: -160px;
            @include span-columns(8);
        }
        @include media ($desktop) {
            top: -220px;
        }
    }

    aside {
        @include span-columns(4);
    }
}

p {
    max-width: 1080px;
    display: inline-block;
}

