.logo {
    
    display:table-cell;
    vertical-align:middle;

    @include media($mobile) {
        padding: 1em 0; 
        display: block;
    }
}
